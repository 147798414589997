<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputTextArea from "@/components/common/questionnaires/question/inputs/InputTextArea";

export default {
  name: 'OptionsHealthInstructions',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'You have chosen to include legally binding instructions for your attorneys. Please state them clearly and precisely below:',
        subTitle:  'You\'ve decided to include legally binding instructions for your Health and Welfare LPA, so be aware your attorneys must follow these instructions. Ensure they are clear, unambiguous, and reflect your wishes. Examples include: "I do not want to be resuscitated if my heart stops," or "I want to be cared for at home for as long as possible," or "I do not want to receive treatment that will prolong my life if I no longer recognise my family". Remember, these instructions must be followed, so ensure they are practical and consider discussing them with a medical professional to understand their implications. Unclear or impossible instructions could invalidate your LPA. It is also sensible to discuss your instructions with your attorneys.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyHealthInstructions', // fake field name required
        formPath: 'data.attorneys.health.details.generalOptions.instructions',
        jsonSaveField: 'instructions'
      },
      args: {
        placeholder: 'e.g. I do not want to receive treatment that will prolong my life if I no longer recognise my family',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
