<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputTextArea from "@/components/common/questionnaires/question/inputs/InputTextArea";

export default {
  name: 'OptionsPropertyInstructions',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'You have chosen to include legally binding instructions for your attorneys. Please state them clearly and precisely below:',
        subTitle:  'You\'ve opted to include legally binding instructions in your Property and Financial Affairs LPA, meaning your attorneys must adhere to them.  We generally advise against this due to the risk of making the LPA inflexible and potentially invalid. However, if you are determined to include instructions, be meticulous in their wording. Examples include: "My attorneys must obtain the consent of all of my children before selling my house," or "My attorneys must invest my money only in ethical funds that do not invest in arms or tobacco," or "My attorneys must provide me with a monthly account of all transactions made." These instructions must be capable of being followed in all circumstances, or your LPA may be invalid. Ensure you discuss them thoroughly with your attorneys.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPropertyInstructions', // fake field name required
        formPath: 'data.attorneys.property.details.generalOptions.instructions',
        jsonSaveField: 'instructions'
      },
      args: {
        placeholder: 'e.g. My attorneys must obtain the consent of all of my children before selling my house',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
