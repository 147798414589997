<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { general} from "@/components/common/questionnaires/question/options/general";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'NotifiedPeopleHealthYN',
  mixins: [general, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Do you want to require that specific individuals be formally notified when your Lasting Power of Attorney (LPA) is registered with the Office of the Public Guardian?',
        subTitle: 'While you can name people to be notified, we generally discourage this.  It creates an extra administrative step, and it is often unnecessary, as registration does not mean the LPA is immediately being used, it can be registered at any point after being completed and can sit unused, but ready, for years.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'notifiedPeopleHealthYN', // fake field name required
        formPath: 'data.notifiedPeople.health.details.enabled',
        jsonSaveField: 'enabled'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
