<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="booleanYesNo"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import { lpaRelationshipHelpers } from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaRelationshipHelpers";
import { lpaPartnerHelpers } from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { general} from "@/components/common/questionnaires/question/options/general";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";

export default {
  name: 'PartnerPropertyPrimaryAttorneyYN',
  mixins: [
    general,
    questionLoadHelpers,
    lpaPartnerHelpers,
    lpaRelationshipHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `Do you wish to appoint ${this.partner.name_first} ${this.partner.name_last} as the primary attorney to manage property and financial affairs in the Lasting Power of Attorney (LPA)?`,
        subTitle: `This question asks if you want to legally authorise your partner or spouse to make decisions about your money and property, such as paying bills, managing bank accounts, and selling your house, should you become unable to.  Consider if you trust them implicitly with these matters and if they have the necessary skills. If you don't want to appoint your partner, you can choose someone else or more than one person.`,
        tip: null,
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'primaryPartnerAttorneysProperty',
        formPath: 'data.attorneys.property.details.primaryPartner',
        jsonSaveField: 'primaryPartner'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
