<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="healthOptions.lifeSustainingTreatment"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'OptionHealthLifeTreatmentYN',
  mixins: [lpaOptions, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Do you want to give your attorney(s) the authority to make decisions about life-sustaining treatment on your behalf, including the ability to give or refuse consent to such treatment?',
        subTitle: '"Life-sustaining treatment" means any treatment that a medical professional deems necessary to keep you alive. This could include things like ventilation, CPR, or artificial feeding.  By answering "Yes", you are giving your attorney(s) the power to make these critical decisions, following discussions with medical professionals, if you are unable to. By answering "No", you are indicating that you want these decisions to be made by the healthcare professionals only, based on their assessment of your best interests, though your attorney can still be consulted.  This is a serious responsibility, so it is important to think carefully about who you want to make these decisions and to discuss your wishes with them.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyHealthLifeTreatmentYN', // fake field name required
        formPath: 'data.attorneys.health.details.generalOptions.health_life_sustaining_treatment',
        jsonSaveField: 'health_life_sustaining_treatment'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
