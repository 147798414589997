<template>
  <QuestionBase
      :id="db.saveField"
      :errors="[...jsErrors]"
      :question="question"

      :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
            v-for="(person, index) in data"
            :id="'attorneyPropertyPrimaryData' + index"
            :key="'attorneyPropertyPrimaryData' + person.id + index"
            :ref="'attorneyPropertyPrimaryData' + index"
            :select-mode="true"
            :index="index"
            :selected="true"
            :title="cardTitle(person)"
            :value="data[index]"
            :show-delete="false"
            :show-save="!person.placeholder"
            type="attorneyPropertyPrimaryData"
            :loading="loading"
            @click="deselectConfirm(
                  person,
                  'Remove person from your primary Attorneys?'
                )
          "
            @delete="deletePerson(person.id)"
            @save="
            savePerson(
              null,
              'attorneyPropertyPrimaryData' + index)"
        >
          <PersonLpa
              v-if="person.type === 'person'"
              v-model="data[index]"
              :key="'attorneyPropertyPrimary-form-data' + person.id"
              :errors-post="errorsPatch"
              :no-save="true"
              :objectId="person.id"
              @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template
          v-if="
          ((!details.primaryPartner) ||
          (!partner)) && data.length < maxAllowedPeople
        "
      >
        <b-button
            class="btn-question w-100"
            @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Attorney
        </b-button>
      </template>
    </transition>

    <LpaPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="data"
        :show-charities="false"
        :show-groups="false"
        :sub-title="'Select attorneys by selecting existing people or adding new people. You can select multiple individuals for this role.'"
        :hide-type-options="true"
        :disabledPersonFunction="disableSelectPersonFunction"
        :maximum-allowed-selection="maxAllowedPeople - data.length"
        maximumAllowedWarningText="Maximum number of Primary Attorneys selected"
        warningMessage="Person must be over 18 years old"
        title="Add Primary Attorney"
        @dataSelectedUpdate="data=$event"
        @save="save"
    />
  </QuestionBase>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import {lpaPeopleHelpers} from "@/components/common/questionnaires/question/questions/lpa/helpers/lpaPeopleHelpers";
import {lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import LpaPeopleModal from "@/components/common/questionnaires/question/questions/lpa/helpers/LpaPeopleModal";

export default {
  name: "PrimaryPropertyAttorneys",
  components: {
    LpaPeopleModal,
    PersonLpa,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    dateFormat,
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    lpaPeopleHelpers,
    lpaPartnerHelpers,
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    details: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      db: {
        saveLocation: "product_lpa_data",
        saveField: "primaryAttorneysProperty",
        formPath: "data.attorneys.property.details.primary",
        jsonSaveField: "primary",
        personSaveLocation: 'client_person_lpa'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage:
            "You must have more than one Primary Attorney, click to select",
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
      maxAllowedPeople: 6
    };
  },
  computed: {
    question() {
      return {
        title: "Add Your Attorney(s) for Property and Financial Affairs",
        subTitle: "Please click the \"Add\" button to enter the full name, address, and contact information for each person you wish to appoint as your attorney for your property and financial affairs.  These individuals will be responsible for managing your finances, paying your bills, and making decisions about your property if you are unable to.  You can add more than one attorney if you choose. If you have already added an attorney and need to add another, click the add button again.",
        tip: null,
      };
    },
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    }
  },
  methods: {
    disableSelectPersonFunction(person) {
      return this.isChild(person.dob)
    }
  }
};
</script>
