<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputTextArea from "@/components/common/questionnaires/question/inputs/InputTextArea";

export default {
  name: 'OptionsHealthAttorneysPrimaryWorkTogetherOther',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Please specify which decisions your attorneys must make jointly, and which they can make severally (independently):',
        subTitle:  'Having opted to have your attorneys make some decisions jointly and others severally, you now need to define which decisions fall into each category.  "Joint" decisions require all attorneys to agree, providing a safeguard for significant matters. "Several" decisions can be made by any one of your attorneys independently. For example, you might specify that selling your property must be a joint decision, while paying everyday bills can be handled severally.  Carefully consider the implications of each type of decision and clearly outline them to ensure your attorneys understand your wishes. You should also discuss your choices with your attorneys.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPrimaryHealthWorkTogetherOther', // fake field name required
        formPath: 'data.attorneys.health.details.primaryOptions.work_together_other_details',
        jsonSaveField: 'work_together_other_details'
      },
      args: {
        placeholder: 'e.g. ...',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
