<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputTextArea from "@/components/common/questionnaires/question/inputs/InputTextArea";

export default {
  name: 'OptionsPropertyPreferences',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Please describe any preferences you have regarding your property and financial affairs that you would like your attorneys to consider when making decisions on your behalf:',
        subTitle:  'Clearly outline your wishes regarding your finances and assets. Examples include: "I would prefer my investments to be managed ethically," "I would like to continue supporting [Charity Name] with annual donations," or "I would prefer my property to be rented out rather than sold if I am unable to live in it." These preferences are not legally binding but provide valuable context for your attorneys, allowing them to manage your affairs in a way that aligns with your values and priorities. Discuss these preferences in detail with your attorneys.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPropertyPreferences', // fake field name required
        formPath: 'data.attorneys.property.details.generalOptions.preferences',
        jsonSaveField: 'preferences'
      },
      args: {
        placeholder: 'e.g. I would prefer my property to be rented out rather than sold if I am unable to live in it',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
