<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputTextArea from "@/components/common/questionnaires/question/inputs/InputTextArea";

export default {
  name: 'OptionsHealthPreferences',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Please describe any preferences you have regarding your health and welfare that you would like your attorneys to consider when making decisions on your behalf:',
        subTitle:  'Clearly outline your wishes regarding your care, lifestyle, and well-being. Examples include: "If possible, I would prefer to receive care at home rather than in a residential facility," "I would like to continue attending my local community centre," or "I prefer a vegetarian diet."  While not legally binding, these preferences offer valuable insight into your values and wishes. Discuss these preferences in detail with your attorneys so they can make informed decisions that respect your individuality and priorities, should you lose capacity.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyHealthPreferences', // fake field name required
        formPath: 'data.attorneys.health.details.generalOptions.preferences',
        jsonSaveField: 'preferences'
      },
      args: {
        placeholder: 'e.g. I prefer a vegetarian diet',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
