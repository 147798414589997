<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { general } from "@/components/common/questionnaires/question/options/general";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";


export default {
  name: 'OptionHealthPreferencesYN',
  mixins: [general, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Would you like to express any non-binding preferences to guide your attorneys when they are making decisions on your behalf?',
        subTitle: 'For example, you might state a preference for a particular type of care setting, such as staying at home as long as possible or moving to a specific care home. You could express wishes regarding your daily routine, social activities, or dietary needs.  Discussing these preferences with your attorneys will help them understand your values and make decisions that align with your wishes, should you become unable to make them yourself.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyHealthPreferencesYN', // fake field name required
        formPath: 'data.attorneys.health.details.generalOptions.preferencesYN',
        jsonSaveField: 'preferencesYN'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
