<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { general} from "@/components/common/questionnaires/question/options/general";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'BackupHealthAttorneysYN',
  mixins: [general, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    question() {
      let people = this.listPeopleAddressDob({people: this.details.primary, noDob: true, personBold: true})
      let oneOrMore = this.oneOrMore({
        list: this.details.primary,
        word: 'attorney'
      })

      return {
        title: `Do you want to appoint one or more replacement attorneys to make health and welfare decisions for you if all of your initially chosen attorney(s), ${people}, are unable or unwilling to act for you?`,
        subTitle: `This is about ensuring someone you trust can make decisions about your health and care if your primary attorney(s) cannot. A replacement attorney steps in only if all the attorneys you initially chose are unable to act. You named ${people} as your ${oneOrMore}. These decisions could involve medical treatment, where you live, or your daily care. Consider whether you want a backup to ensure these important decisions can always be made. You can appoint more than one person as a replacement. You can select "No" if you don't want to appoint any replacements.`,
        tip: null,
      }
    },
  },
  data () {
    return {
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'backupAttorneysHealthYN', // fake field name required
        formPath: 'data.attorneys.health.details.backupYN',
        jsonSaveField: 'backupYN'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
