<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { general } from "@/components/common/questionnaires/question/options/general";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";


export default {
  name: 'OptionPropertyPreferencesYN',
  mixins: [general, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Would you like to express any non-binding preferences to guide your attorneys when they are making decisions on your behalf?',
        subTitle: 'For example, you might indicate preferred investment strategies (e.g., ethical investments), charitable donations you\'d like to continue, or how you want your property to be used. This approach provides valuable context for your attorneys while allowing them the flexibility to adapt to changing financial circumstances. Discussing these preferences with your attorneys will assist them in managing your affairs responsibly and in line with your values.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPropertyPreferencesYN', // fake field name required
        formPath: 'data.attorneys.property.details.generalOptions.preferencesYN',
        jsonSaveField: 'preferencesYN'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
