<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="propertyOptions.authority"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        class="radio-options-wide"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'OptionPropertyAuthorityYN',
  mixins: [lpaOptions, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Do you want to authorise your attorney(s) to make decisions on your behalf, with your consent, even while you still have mental capacity to make those decisions yourself?',
        subTitle: 'This question, which only relates to Property and Financial Affairs Lasting Powers of Attorney (LPAs), asks if you want to give your attorney(s) the option to assist you with managing your finances and property even if you are still capable of making decisions. If you answer "Yes", your attorneys will be able to act on your behalf, but only with your express permission for each action they take. You retain full control and can overrule them. This can be helpful if you want assistance with complex transactions or find managing your affairs burdensome.  If you answer "No", your attorneys will only be able to act if you lose mental capacity. Discuss this option with your attorneys to ensure you both understand the implications.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPropertyAuthorityYN', // fake field name required
        formPath: 'data.attorneys.property.details.generalOptions.authority_when',
        jsonSaveField: 'authority_when'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
