<template>
  <QuestionBase
      :id="db.saveField"
      :errors="[...jsErrors]"
      :question="question"

      :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
            v-for="(person, index) in data"
            :id="'attorneyHealthPrimaryData' + index"
            :key="'attorneyHealthPrimaryData' + person.id + index"
            :ref="'attorneyHealthPrimaryData' + index"
            :select-mode="true"
            :index="index"
            :selected="true"
            :title="cardTitle(person)"
            :value="data[index]"
            :show-delete="false"
            :show-save="!person.placeholder"
            :loading="loading"
            type="attorneyHealthPrimaryData"
            @click="deselectConfirm(
                  person,
                  'Remove person from your primary Attorneys?'
                )
          "
            @delete="deletePerson(person.id)"
            @save="
            savePerson(
              null,
              'attorneyHealthPrimaryData' + index)"
        >
          <PersonLpa
              v-if="person.type === 'person'"
              v-model="data[index]"
              :key="'attorneyHealthPrimary-form-data' + person.id"
              :errors-post="errorsPatch"
              :no-save="true"
              :objectId="person.id"
              @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template
          v-if="
          ((!details.primaryPartner) ||
          (!partner)) && data.length < maxAllowedPeople
        "
      >
        <b-button
            class="btn-question w-100"
            @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Attorney
        </b-button>
      </template>
    </transition>

    <LpaPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="data"
        :show-charities="false"
        :show-groups="false"
        title="Add Primary Attorney"
        :disabledPersonFunction="disableSelectPersonFunction"
        warningMessage="Person must be over 18 years old"
        :sub-title="'Select attorneys by selecting existing people or adding new people. You can select multiple individuals for this role.'"
        :hide-type-options="true"
        :maximum-allowed-selection="maxAllowedPeople - data.length"
        maximumAllowedWarningText="Maximum number of Primary Attorneys selected"
        @dataSelectedUpdate="data=$event"
        @save="save"
    />
  </QuestionBase>
</template>

<script>
import { personHelpers } from "@/mixins/personHelpers";
import { peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import { lpaPeopleHelpers} from "@/components/common/questionnaires/question/questions/lpa/helpers/lpaPeopleHelpers";
import { lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import LpaPeopleModal from "@/components/common/questionnaires/question/questions/lpa/helpers/LpaPeopleModal";

export default {
  name: "PrimaryHealthAttorneys",
  components: {
    LpaPeopleModal,
    PersonLpa,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
      dateFormat,
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    lpaPeopleHelpers,
    lpaPartnerHelpers,
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    details: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      db: {
        saveLocation: "product_lpa_data",
        saveField: "primaryAttorneysHealth",
        formPath: "data.attorneys.health.details.primary",
        jsonSaveField: "primary",
        personSaveLocation: 'client_person_lpa'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage:
            "You must have more than one Primary Attorney, click to select",
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
      maxAllowedPeople: 6
    };
  },
  computed: {
    question() {
      return {
        title: "Add Your Attorney(s) for Health and Welfare Decisions",
        subTitle: "Please click the \"Add\" button to enter the details of each person you wish to appoint as your attorney for decisions about your health and welfare. You'll need to provide their full name, address, and contact information.  These are the people who will make decisions about your medical treatment and personal care if you lose the ability to do so yourself. You can add more than one attorney if you wish. If you have already added an attorney and need to add another, click the add button again.",
        tip: null,
      };
    },
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    disableSelectPersonFunction (person) {
      return this.isChild(person.dob)
    }
  }
};
</script>
