<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="workTogether"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        class="radio-options-wide"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'OptionPropertyAttorneysPrimaryWorkTogetherYN',
  mixins: [lpaOptions, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'As you are appointing more than one primary attorney, how do you want them to make decisions together?',
        subTitle: `This question asks how your multiple attorneys will share decision-making responsibilities. You can choose "Jointly," meaning all attorneys must agree on every decision.  "Jointly for Some Decisions, Severally for Others" lets you specify which decisions require unanimous agreement and which can be made individually. However, we generally recommend "Jointly and Severally," which allows attorneys to act together or independently. While this requires a high level of trust, it offers the most flexibility, enabling quicker action in time-sensitive situations. You will be asked to specify any joint decisions you would like to impose in the next question. Discuss this with your chosen attorneys to ensure they are comfortable with this arrangement, especially as they can be held liable for each other's actions.`,
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPrimaryPropertyWorkTogetherYN', // fake field name required
        formPath: 'data.attorneys.property.details.primaryOptions.work_together',
        jsonSaveField: 'work_together'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
